<template>
  <div v-if="dataInfo" id="applyService">
    <!-- 商品 -->
    <div class="goods-card van-hairline--top">
      <van-checkbox-group @change="selectProduct" v-model="goodsCheck">
        <!-- 单品、规格 -->
        <van-checkbox v-for="(item, index) in product" :key="index" :name="item.dispatch_goods_id" label-disabled>
          <van-card>
            <template v-slot:thumb>
              <van-image :src="item.goods_pic" lazy-load fit="contain"/>
            </template>
            <template v-slot:title>
              <div class="card-wrap">
                <div class="card-left">
                  <p class="card-title">{{item.goods_title}}</p>
                </div>
                <div class="card-right">
                  <div class="card-right-wrap">
                    <p class="card-nowrap">${{item.sale_price}}</p>
                  </div>
                  <div class="goods-input">
                    <van-stepper v-model="item.number" min="1" :max="productMaxNumber[item.dispatch_goods_id]" button-size="25" input-width="38"/>
                  </div>
                </div>
                <p class="goodsInfo">{{item.goods_title == item.product_name ? '' : item.product_name}}</p>
              </div>
            </template>
          </van-card>
        </van-checkbox>
      </van-checkbox-group>
    </div>
    <!-- 原因 -->
    <div class="getInfo">
      <!-- 售后类型 -->
      <van-cell @click="showStatus('types')" :title="language.l_238" :value="types.name" is-link required arrow-direction="down" />
      <!-- 货物状态 -->
      <van-cell @click="showStatus('states')" :title="language.l_239" :value="states.name" is-link required arrow-direction="down" />
      <!-- 申请原因 -->
      <van-cell @click="showStatus('reasons')" :title="language.l_240" :value="reasons.name" is-link required arrow-direction="down" />
      <!-- 申请说明    请输入申请说明 -->
      <van-field v-model="apply_msg" :label="language.l_241" type="textarea" :placeholder="language.l_242" autosize/>
    </div>
    <div class="status_price">
      <!-- 订单金额    请选择售后商品 -->
      <van-field v-model="order_amount" v-show="types.value == 1 || types.value == 2" :label="language.l_243" type="number" :placeholder="language.l_244" readonly clearable/>
      <!-- 运费金额    请输入运费金额 -->
      <van-field @input="inputShipping" v-model="shipping_fee" v-show="types.value == 1 && states.value == 1" :error-message="shippingError" :label="language.l_245" type="number" :placeholder="language.l_246" clearable/>
      <!-- 售后金额    请输入售后金额 -->
      <van-field @input="inputAmount" v-model="amount" v-show="types.value == 1 || types.value == 2" :error-message="amountError" :label="language.l_247" type="number" :placeholder="language.l_248" clearable/>
    </div>
    <!-- 图片 -->
    <div class="getImage">
      <!-- 上传凭证 -->
      <p class="getImage-title">{{language.l_249}}</p>
      <van-uploader v-model="fileList" :max-count="4" :after-read="uploader_img"/>
    </div>
    <!-- 用户信息 -->
    <div class="getUserInfo">
      <!-- 联系方式    请输入手机号 -->
      <van-field @input="inputPhone" v-model="phone" :error-message="phoneError" type="tel" :label="language.l_250" :placeholder="language.l_251" required clearable/>
      <!-- 退款方式    余额 -->
      <van-cell :title="language.l_252" :value="language.l_29" />
    </div>
    <!-- 弹窗 - 选择原因 -->
    <van-popup @close="show= false" v-model="show" position="bottom" get-container="#app" class="service-status">
      <div class="model-wrap">
        <div class="model-header">{{modelHeader}}</div>
        <div class="model-radios">
          <van-radio-group v-model="service_radio">
            <van-cell-group :border="false">
              <van-cell v-for="(item, index) in model_list" :key="index" @click="selectStatus(item.id, item.value, item.name, is_disabled(item.value))" :title="item.name" clickable>
                <van-radio @click="changeStatus(item.id, item.value, item.name)" :name="item.id" :disabled="is_disabled(item.value)"/>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
      </div>
    </van-popup>
    <!-- 确定 -->
    <div @click="confirmService" class="getSubmit">{{language.l_87}}</div>
  </div>
</template>

<script>
import {applyService, uploadImage, addService} from '@api';

export default {
  data() {
    return {
      dataInfo: null,
      order_sn: null,
      show: false,
      product: [], // 处理后的-商品数据
      productMaxNumber: null, // 每个商品对应的最大可选数量
      goodsCheck: [], // 订单勾选
      no_logistics: false, // 勾选商品都为未发货
      model_type: '', // 弹窗类型
      model_list: [], // 类型数据
      service_radio: '', // 原因勾选
      types: {}, // 选择的售后类型
      states: {}, // 选择的货物状态
      reasons: {}, // 选择的申请原因
      apply_msg: '', // 申请说明
      phone: '', // 手机号
      phoneError: '', // 手机号错误提示
      bank_card: '', // 收款账号
      cardError: '', // 收款账号错误提示
      fileList: [], // 上传的图片
      amountError: '',
      shippingError: '',
      reduceError: '',
      language: this.$store.getters.language
    }
  },
  created() {
    const {id: order_id} = this.$route.query
    applyService(order_id).then(res => {
      const {order, goods} = res.data
      this.dataInfo = res.data
      this.order_sn = order.order_sn
      this._handleProduct(goods);
    }) 
    .catch(err => {
      this.$toast(err)
      this.$router.go(-1)
    })

    // 默认手机号
    this.phone = this.$store.state.userInfo.tel;
    this.bank_card = this.$store.state.userInfo.tel;
  },
  methods: {
    _handleProduct(goods) { // 处理商品数据 - 用于提交
      let product = {};
      let productMaxNumber = {};
      // 单品
      Object.values(goods).forEach(item => {
        product[`${item.id}`] = JSON.parse(JSON.stringify(item));
        product[`${item.id}`]['dispatch_goods_id'] = item.id;
        product[`${item.id}`]['goods_title'] = item.product_name;
        product[`${item.id}`]['product_code'] = item.product_code;
        product[`${item.id}`]['product_type'] = 1;
        product[`${item.id}`].number = item.number;

        productMaxNumber[`${item.id}`] = item.number;
      });

      this.product = product
      this.productMaxNumber = productMaxNumber;
    },
    selectProduct(val) {
      if(val.length == 0) return this.no_logistics = false;

      const {product} = this;
      let goods = [];
      val.forEach(item => goods.push(product[item])); // 筛选选择的数据
      const logistics_arr = 0
      if(logistics_arr.length == 1 && !logistics_arr[0]) { // 如果勾选都为未发货商品
        this.no_logistics = true;
        const {states, dataInfo} = this;
        if(states.hasOwnProperty('value') && states.value != '0') { // 如果选择了发货状态，且状态不是未发货
          this.states = dataInfo.states.filter(item => item.value == '1')[0]
        }
      } else {
        this.no_logistics = false;
      }
    },
    showStatus(type) { // 显示原因弹窗
      this.show = true;
      const {dataInfo} = this;
      if(type == 'types') { 
        this.model_list = dataInfo.types; 
        this.service_radio = this.types.id || '';
        this.amountError = '';
        this.shippingError = '';
        this.reduceError = '';
      };
      if(type == 'states') {
        this.model_list = dataInfo.states;
        this.service_radio = this.states.id || ''
      };
      if(type == 'reasons') {
        this.model_list = dataInfo.reasons;
        this.service_radio = this.reasons.id || ''
      };
      this.model_type = type;
    },
    selectStatus(id, value, name, is_disabled) { // 选择原因
      if(is_disabled) return;
      if(id == this.service_radio) return this.show = false;
      const {model_type} = this;
      const selectObj = {id, value, name};
      if(model_type == 'types') this.types = selectObj;
      if(model_type == 'states') this.states = selectObj;
      if(model_type == 'reasons') this.reasons = selectObj;
      this.service_radio = id;
      this.show = false;
    },
    inputPhone(val) { // 输入手机号
      const regExp = /^1[3456789]\d{9}$/;
      this.phoneError = val ? regExp.test(val) ? '' : this.language.l_253 : this.language.l_254  //请输入正确的手机号    手机号码不能为空
    },
    inputCard(val) { // 输入收款账号
      this.cardError = val ? '' : this.language.l_255  //收款账号不能为空
    },
    inputAmount(val) { // 输入售后金额
      this.amountError = val && val > this.order_amount ? this.language.l_256 : '';  //售后金额不能大于订单金额
    },
    inputShipping(val) { // 输入运费金额
      this.shippingError = val && val > this.order_amount ? this.language.l_257 : '';  //运费金额不能大于订单金额
    },
    inputReduce(val) { // 输入扣款金额
      this.reduceError = val && val > this.order_amount ? this.language.l_258 : '';  //扣款金额不能大于订单金额
    },
    uploader_img(file) { // 上传图片
      file.status = 'uploading';
      file.message = this.language.l_259;  //上传中
      let formData = new FormData();
      formData.append('file', file.file);
      uploadImage(formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => {
        file.status = 'done';
        file.message = this.language.l_188;  //上传成功
        file.content = res.data.url;
      }).catch(() => {
        file.status = 'failed';
        file.message = this.language.l_260;  //上传失败
      })
    },
    formVerify() { // 提交校验
      const {goodsCheck, types, states, reasons, fileList} = this;
      const {bank_card, phoneError, cardError, amountError, shippingError, reduceError} = this;
      if(goodsCheck.length == 0) return this.$toast(this.language.l_261);  //请勾选需要售后的商品
      if(Object.keys(types).length == 0) return this.$toast(this.language.l_262);  //请选择售后类型
      if(Object.keys(states).length == 0) return this.$toast(this.language.l_263);  //请选择货物状态
      if(Object.keys(reasons).length == 0) return this.$toast(this.language.l_264);  //请选择申请原因
      if(amountError) return this.$toast(shippingError);
      if(shippingError) return this.$toast(shippingError);
      if(reduceError) return this.$toast(reduceError);

      const pics = fileList.filter(item => item.status == 'done') // 筛选上传成功的图片
      if(phoneError) return this.$toast(phoneError); // 手机号有默认值 若格式错误一定会有错误提示
      if(!bank_card) return this.$toast(this.language.l_255); // 银联没有默认值 所以需要判断    收款账号不能为空
      if(cardError) return this.$toast(cardError);

      return false
    },
    confirmService() { // 提交申请
      if(Boolean(this.formVerify())) return;

      const {goodsCheck, product, fileList, types, states, reasons} = this;
      const {dataInfo: {order_id}, order_sn, apply_msg} = this;
      const {phone, bank_card, order_amount, amount, shipping_fee} = this;
      // 获取勾选数据
      let goods = {};
      goodsCheck.forEach(item => {
        const obj = JSON.parse(JSON.stringify(product[item]));
        delete obj.logistics_info; // 删除商品数据中的物流信息字段
        goods[item] = obj
      })

      // 获取上传图片
      let pics = fileList.filter(item => item.status == 'done').map(item => item.content).join(';')
      
      const params = {
        order_id, // 订单id
        order_sn, // 订单号
        type: types.value, // 售后类型
        reason: reasons.value, // 申请原因
        package_status: states.value, // 货物状态
        remark: apply_msg, // 申请说明
        order_amount, // 订单金额
        amount, // 售后金额
        shipping_fee, // 运费金额
        contact_phone: phone, // 联系方式
        account: bank_card, // 收款账号
        pics, // 上传图片
        goods, // 商品数据
      }

      addService(params).then(res => {
        this.$toast.success(this.language.l_265);  //提交成功
        this.$store.commit('setDelayJump', () => this.$router.replace({name: 'ServiceInfo', params: {id: res.data}, query: {isUpdate: true}}))
      })
    },
    is_disabled(val) { // 是否禁用
      const {model_type, no_logistics} = this;
      return model_type == 'states' && no_logistics && val != '1'
    },
    changeStatus(id, value, name) {
      const {model_type} = this;
      const selectObj = {id, value, name};
      if(model_type == 'types') this.types = selectObj;
      if(model_type == 'states') this.states = selectObj;
      if(model_type == 'reasons') this.reasons = selectObj;
      this.service_radio = id;
    }
  },
  computed: {
    goodsInfo() {return this.dataInfo.goods},
    modelHeader() { // 弹窗标题
      const {model_type} = this;
      if(model_type == 'types') return this.language.l_238;  //售后类型
      if(model_type == 'states') return this.language.l_239;  //货物状态
      if(model_type == 'reasons') return this.language.l_240;  //申请原因
    },
    order_amount() { // 订单金额
      const {goodsCheck, product} = this;
      if(goodsCheck.length > 0) {
        let totalPrice = 0;
        goodsCheck.forEach(item => totalPrice += product[item].sale_price * product[item].number);
        return totalPrice.toFixed(2);
      } else {return ''}
    },
    shipping_fee() {
      const {goodsCheck, product} = this;
      if(goodsCheck.length > 0) {
        let totalPrice = 0;
        goodsCheck.forEach(item => totalPrice += product[item].express_fee * product[item].number);
        return totalPrice.toFixed(2);
      } else {return ''}
    },
    amount() {
      const {goodsCheck, product, types, states} = this;
      if(goodsCheck.length > 0) {
        let totalPrice = 0;

        if(types.value == 1 && states.value == 1) {
          goodsCheck.forEach(item => totalPrice += product[item].sale_price * product[item].number + product[item].express_fee * product[item].number);
        }else if(types.value == 3) {
          totalPrice = 0
        }else {
          goodsCheck.forEach(item => totalPrice += product[item].sale_price * product[item].number);
        }
        return totalPrice.toFixed(2);
      } else {return ''}
    }
  }
}
</script>

<style lang="less" scoped>
#applyService {
  .goods-card {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    .van-checkbox {
      & + .van-checkbox {
        margin-top: 10px;
      }
    }
    .van-card {
      box-sizing: border-box;
      padding: 0;
      border-radius: 10px;
      background-color: #fff;
      .van-card__thumb {
        box-shadow: 1px 0px 2px rgba(0,0,0,.1);
        background-color: #fff;
        border-radius: 10px;
        .van-image {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .card-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .card-left {
          .card-title {
            line-height: 1.5;
          }
        }
        .card-right {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
          .card-nowrap {
            white-space: nowrap;
            color: red;
            font-weight: bold;
          }
        }
        .goodsInfo {
          text-align: right;
          margin-top: 10px;
          color: #999999;
          line-height: 1.5;
        }
      }
    }
    .goods-input {
      width: 80px;
      margin-top: 5px;
    }
    .goods-list {
      .van-card {
        margin-bottom: 10px;
        margin-left: 30px;
      }
      .goods-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        .goods-title {
          flex: 1;
          margin-right: 10px;
        }
      }
    }
  }

  /deep/ .van-checkbox__label {
    flex: 1;
  }
 
  .getInfo {
    .van-cell {
      .van-cell__value {
        flex: 3;
        -webkit-flex:3;
        text-align: left;
      }
    }
  }
  .status_price {
    margin-top: 10px;
  }
  
  .getImage {
    box-sizing: border-box;
    padding: 10px 10px 0;
    margin-top: 10px;
    background-color: #fff;
    .getImage-title {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .getUserInfo {
    margin-top: 10px;
    .van-cell {
      .van-cell__value {
        flex: 3;
        -webkit-flex:3;
        text-align: left;
      }
    }
  }

  .getSubmit {
    width: 350px;
    height: 40px;
    margin: 20px auto;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    border-radius: 5px;
    background-image: @grad_left;
  }
}
</style>